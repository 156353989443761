import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";

import Cell from "./hcpAssignmentHeader/Cell";
import Highlight from "./hcpAssignmentHeader/Highlight";
import Title from "./hcpAssignmentHeader/Title";
import { FacilityDetail } from "types/facility/facilityDetail";
import { WorkWeek } from "commons/Enums";
import { getFacilityById } from "api/FacilitiesApi";

type IProps = {
  jobAssignment: any;
  thirdColumnNodes?: any;
  columnWidths: ColumnWidths[];
};

export interface ColumnWidths {
  minWidth: number;
  columnSpans: number[];
}

const HCPAssignmentHeader = (props: IProps) => {
  const { jobAssignment } = props;

  // Get facility data (to retrieve whether Manager Signature is Required)
  const [facilityInfo, setFacilityInfo] = useState(null as unknown as FacilityDetail);

  useEffect(() => {
    if (jobAssignment.clientId && !jobAssignment.documentType) {
      // For Profile, jobAssignment does not include Requires Manager Signature or Requires Additional Docs, so need to request separately.
      getFacilityById(jobAssignment.clientId)
        .then((facility) => {
          setFacilityInfo(facility);
        })
        .catch((e) => console.error(e));
    }
  }, []);

  // Set columns widths based on the width of the Grid (not the entire screen width):
  const [parentWidth, setParentWidth] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getColumnSpanBasedOnParentWidth = (columnNum: number): number => {
    const theWidthEntry = props.columnWidths.find((cw) => cw.minWidth <= parentWidth);
    if (theWidthEntry) {
      return theWidthEntry.columnSpans[columnNum];
    }
    return 1;
  };

  // Data to show:

  const ID = jobAssignment.candidateSSN;
  const startDate = moment(jobAssignment.jobAssignmentStartDate).format("MM/DD/YYYY");
  const endDate = moment(jobAssignment.jobAssignmentEndDate).format("MM/DD/YYYY");
  const numberOfShifts =
    jobAssignment.numberOfShifts !== undefined ? jobAssignment.numberOfShifts : " -- ";
  const hoursPerShift = (
    jobAssignment.scheduledHours && jobAssignment.numberOfShifts
      ? (parseFloat(jobAssignment.scheduledHours) / jobAssignment.numberOfShifts).toFixed(2)
      : "--"
  ) as string;
  const hours = jobAssignment.scheduledHours !== undefined ? jobAssignment.scheduledHours : " -- ";

  const arraySpecialists = jobAssignment.billingSpecialists ?? [];
  const billingSpecialist =
    arraySpecialists.length > 0 ? arraySpecialists.join(", ") : "No Billing Specialists";

  const facility = jobAssignment.clientName;
  const cityState = jobAssignment.clientLocation;

  const workWeek = jobAssignment.workWeek;
  const workWeekStarts = WorkWeek[jobAssignment.workWeek as never];
  const nonStandardWorkWeekStart = workWeek !== null && workWeek !== 0;

  const managerSignatureRequired =
    (jobAssignment.clientTimecardRequiresManagerSignature as boolean) ??
    (facilityInfo?.timecardRequiresManagerSignature as boolean);

  let additionalDocument = "No";
  let additionalDocumentRequired = false;

  if (facilityInfo && facilityInfo.additionalDocumentsRequired) {
    const theDoc = facilityInfo.documentTypes.find((d) => d.id === facilityInfo.documentType);
    if (theDoc !== undefined) {
      additionalDocumentRequired = facilityInfo.additionalDocumentsRequired;
      if (additionalDocumentRequired) {
        additionalDocument = theDoc.name;
      }
    }
  } else {
    additionalDocumentRequired = jobAssignment.clientAdditionalDocumentsRequired;
    if (additionalDocumentRequired) {
      additionalDocument = jobAssignment.documentType;
    }
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={0} sx={{ marginTop: "5px" }} ref={parentRef}>
          <Grid item xs={getColumnSpanBasedOnParentWidth(0)} className="allCaps">
            <Grid container spacing={0}>
              <Cell>
                <Title>Id:</Title>
                <span data-testid="id">{ID}</span> - <Title>Assignment Period:</Title>
                <span data-testid="assignmentPeriod">
                  {startDate} - {endDate}
                </span>
              </Cell>
              <Cell>
                <Title>Number of Shifts:</Title>
                <span data-testid="numberOfShifts">{numberOfShifts}</span>
              </Cell>
              <Cell>
                <Title>Hours per Shift:</Title>
                <span data-testid="hoursPerShift">{hoursPerShift}</span>
              </Cell>
              <Cell>
                <Title>Scheduled Hours:</Title>
                <span data-testid="scheduledHours">{hours}</span>
              </Cell>
              <Cell>
                <Title>Billing Specialist:</Title>
                <span data-testid="billingSpecialist">{billingSpecialist}</span>
              </Cell>
            </Grid>
          </Grid>

          <Grid item xs={getColumnSpanBasedOnParentWidth(1)} className="allCaps">
            <Grid container spacing={0}>
              <Cell>
                <div className="truncate" style={{ width: "100%" }}>
                  <Tooltip
                    title={("Facility: " + facility).toUpperCase()}
                    placement="bottom-start"
                    arrow>
                    <span>
                      <Title>Facility:</Title>
                      <span data-testid="facility">{facility}</span>
                    </span>
                  </Tooltip>
                </div>
              </Cell>
              <Cell>
                <div className="truncate" style={{ width: "100%" }}>
                  <Tooltip
                    title={("City/State: " + cityState).toUpperCase()}
                    placement="bottom-start"
                    arrow>
                    <span>
                      <Title>City/State:</Title>
                      <span data-testid="cityState">{cityState}</span>
                    </span>
                  </Tooltip>
                </div>
              </Cell>
              <Cell>
                <Highlight highlight={nonStandardWorkWeekStart}>
                  <span>
                    <Title>Work Week Starts:</Title>
                    <span data-testid="workWeekStarts">{workWeekStarts}</span>
                  </span>
                </Highlight>
              </Cell>
              <Cell>
                <Highlight highlight={managerSignatureRequired}>
                  <span>
                    <Title>Manager Signature Required:</Title>
                    <span data-testid="managerSignatureRequired">
                      {managerSignatureRequired ? "Yes" : "No"}
                    </span>
                  </span>
                </Highlight>
              </Cell>
              <Cell>
                <div className="truncate" style={{ width: "100%" }}>
                  <Tooltip
                    title={("Additional Documents Required: " + additionalDocument).toUpperCase()}
                    placement="bottom-start"
                    disableHoverListener={!additionalDocumentRequired}
                    arrow>
                    <span>
                      <Highlight highlight={additionalDocumentRequired}>
                        <span>
                          <Title>Additional Documents Required:</Title>
                          <span data-testid="additionalDocsRequired">{additionalDocument}</span>
                        </span>
                      </Highlight>
                    </span>
                  </Tooltip>
                </div>
              </Cell>
            </Grid>
          </Grid>

          <Grid item xs={getColumnSpanBasedOnParentWidth(2)}>
            <Grid container spacing={0}>
              {props.thirdColumnNodes}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HCPAssignmentHeader;

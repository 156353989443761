import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import MaterialTable, { Column } from "material-table";
import { Link, useHistory } from "react-router-dom";
import { getActiveContracts } from "api/ContractsApi";
import { Contract } from "types/facility/contract";
import { usePermissions } from "hooks";
import { Button } from "@mui/material";

export const Contracts = () => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { canManageContracts } = usePermissions();

  const handleGetActiveContracts = async () => {
    const response = await getActiveContracts();
    setContracts(response);
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Contracts" });
    void handleGetActiveContracts();
  }, []);

  const handleNavigateToContractDetails = () => {
    history.push("/contracts/contractDetails");
  };

  const columns: Column<Contract>[] = [
    {
      title: "Contract Name",
      field: "name",
      render: (row) =>
        canManageContracts ? (
          <Link to={"/contracts/contractDetails/" + row.id}>{row.name} </Link>
        ) : (
          row.name
        ),
    },
    {
      title: "Manager Signature",
      field: "requireManagerSignature",
      lookup: { true: "Yes", false: "No" },
    },
    {
      title: "Include in Triage Plus Report",
      field: "includeInTriagePlusReport",
      lookup: { true: "Yes", false: "No" },
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        sx={{ width: 160, my: 2 }}
        onClick={handleNavigateToContractDetails}>
        Add Contract
      </Button>
      <div style={{ display: "grid" }}>
        <MaterialTable
          style={{ fontSize: 14 }}
          data={contracts}
          columns={columns}
          options={{
            sorting: true,
            filtering: true,
            showTitle: false,
            maxBodyHeight: "60vh",
            pageSize: 20,
            tableLayout: "auto",
            search: false,
          }}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

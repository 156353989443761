import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import "./FacilityDetail.css";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { FacilityDetail as FacilityDetailType } from "types/facility/facilityDetail";
import { usePermissions } from "hooks";
import { ManagerList } from "components/shared/components/managers/ManagerList";
import { createManager, getManagersByFacilityId, updateManager } from "api/ManagersApi";
import TimecardsDownloadDialog from "./TimecardsDownloadDialog";
import { TimecardForwarding } from "./timecardForwarding/TimecardForwarding";
import { LoadingButton } from "shared/loadingButton/LoadingButton";
import { FacilityDetailText } from "./facilityDetailText/FacilityDetailText";
import { UpdatedBy } from "./facilityDetailText/UpdatedBy";
import { WorkWeekStart } from "./workWeekStart/WorkWeekStart";
import { UpdateFacility } from "types/facility/updateFacility";
import { FormErrors } from "types/facility/facilityFormErrors";
import { AdditionalDocuments } from "./additionalDocuments/AdditionalDocuments";
import { getFacilityById, updateFacility as updateFacilityApi } from "api/FacilitiesApi";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { SetupTab } from "types/setup/SetupTab";

const initialFacility: FacilityDetailType = {
  id: "",
  name: "",
  city: "",
  state: "",
  timecardForwarding: false,
  email: "",
  timecardDueDateDay: "",
  timecardDueDateTime: "",
  lateForwarding: false,
  lastUpdatedBy: "",
  lastUpdatedDate: "",
  additionalDocumentsRequired: false,
  timecardRequiresManagerSignature: false,
  timecardDueDateUTC: "",
  workWeekStart: "",
  documentTypes: [],
  documentType: undefined,
  isExternal: false,
  isExchange: false,
};

const formError: FormErrors = {
  emailHasError: false,
  timeHasError: false,
  dayHasError: false,
  acknowledgeHasError: false,
  documentTypeHasError: false,
  invalidManagerListError: false,
};

type FacilityDetailProps = {
  match?: any;
};

const FacilityDetail = (props: FacilityDetailProps) => {
  const { facilityId } = props.match.params;
  const [facility, setFacility] = useState<FacilityDetailType>(initialFacility);
  const [formErrors, setFormErrors] = useState(formError);
  const [isAcknowledgeWorkWeekChange, setIsAcknowledgeWorkWeekChange] = useState(false);
  const [oldWorkWeekStartValue, setOldWorkWeekStartValue] = useState<number | null>(null);
  const showAcknowledge =
    oldWorkWeekStartValue !== null && facility.workWeekStart !== oldWorkWeekStartValue;
  const [isDownloadTimecardDialogOpen, setIsDownloadTimecardDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTimecardsDownload, setIsLoadingTimecardsDownload] = useState(false);
  const [validManagerList, setValidManagerList] = useState(true);
  const snackbar = useSnackbar();

  const { canSeeManagersList } = usePermissions();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getFacilityInfo(facilityId);
  }, [facilityId]);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Facility Details" });
  }, []);

  function setFacilityInfo(facility: any) {
    const timecardDueDate = moment
      .utc(facility.timecardDueDateUTC)
      .local()
      .format("MM/DD/YYYY hh:mm a");
    let timecardDueDateDay = new Date(timecardDueDate).getDay();
    let timecardDueDateTime = moment(timecardDueDate).format("HH:mm");

    if (!facility.timecardDueDateUTC) {
      timecardDueDateDay = -1;
      timecardDueDateTime = "";
    }

    let workWeekStart = facility.workWeekStart;
    if (facility.workWeekStart === null) {
      workWeekStart = "";
    }

    setFacility({
      ...facility,
      timecardDueDateDay,
      timecardDueDateTime,
      workWeekStart,
    });
    if (workWeekStart !== "") {
      setOldWorkWeekStartValue(workWeekStart);
    }
  }

  function getFacilityInfo(facilityId: string) {
    getFacilityById(facilityId)
      .then((facility) => {
        setFacilityInfo(facility);
      })
      .catch((e) => console.error(e));
  }

  function timecardToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setFacility({ ...facility, [event.target.name]: event.target.checked });
  }

  function timecardRequireManagerSignatureToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setFacility({ ...facility, [event.target.name]: event.target.checked });
    if (!event.target.checked) {
      setValidManagerList(true);
    }
  }

  function setValidManagerListFromManagerList(valid: boolean) {
    setValidManagerList(valid);
  }

  function handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number | null>
  ) {
    if (event.target.name) {
      setFacility({ ...facility, [event.target.name]: event.target.value });
      if (event.target.name === "workWeekStart") {
        setIsAcknowledgeWorkWeekChange(false);
      }
    }
  }

  function acknowledgeHasError() {
    return showAcknowledge && isAcknowledgeWorkWeekChange === false;
  }

  function emailHasErrors() {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (facility.email) {
      return !re.test(facility.email);
    }

    return false;
  }

  function timeHasErrors() {
    if (facility.timecardDueDateTime) {
      return false;
    }
    return true;
  }

  function dayHasErrors() {
    if (Number(facility.timecardDueDateDay) >= 0) {
      return false;
    }
    return true;
  }

  function formIsValid() {
    const form: FormErrors = {
      emailHasError: !!facility.timecardForwarding && emailHasErrors(),
      timeHasError: !!facility.timecardForwarding && timeHasErrors(),
      dayHasError: !!facility.timecardForwarding && dayHasErrors(),
      acknowledgeHasError: acknowledgeHasError(),
      documentTypeHasError: !!facility.additionalDocumentsRequired && !facility.documentType,
      invalidManagerListError: !validManagerList,
    };
    setFormErrors(form);
    return !(
      form.emailHasError ||
      form.timeHasError ||
      form.dayHasError ||
      form.acknowledgeHasError ||
      form.documentTypeHasError ||
      form.invalidManagerListError
    );
  }

  function updateFacility() {
    setIsLoading(true);
    let modificationDueDate = moment()
      .day(facility.timecardDueDateDay ?? "Sunday")
      .format("MM/DD/YYYY");
    modificationDueDate = `${modificationDueDate} ${facility.timecardDueDateTime}`;

    const facilityUpdated: UpdateFacility = {
      timecardForwarding: facility.timecardForwarding,
      lateForwarding: facility.lateForwarding,
      email: facility.email,
      timecardDueDateUTC: facility.timecardForwarding
        ? moment(modificationDueDate).utc().format("MM/DD/YYYY hh:mm a")
        : null,
      additionalDocumentsRequired: facility.additionalDocumentsRequired,
      documentType: facility.documentType ?? null,
      timecardRequiresManagerSignature: facility.timecardRequiresManagerSignature,
      workWeekStart: facility.workWeekStart === "" ? null : facility.workWeekStart,
      isExchange: facility.isExchange,
    };

    updateFacilityApi(facilityId, facilityUpdated)
      .then((response) => {
        snackbar.enqueueSnackbar("Changes saved");
        setFacilityInfo(response);
        setIsAcknowledgeWorkWeekChange(false);
      })
      .catch((e) => {
        snackbar.enqueueSnackbar(`Error updating: ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onSave() {
    if (formIsValid()) {
      updateFacility();
    } else {
      if (formErrors.invalidManagerListError) {
        snackbar.enqueueSnackbar("Must have at least one manager");
      }
    }
  }

  function onBack() {
    if (formIsValid()) {
      history.push(`/setup?tab=${SetupTab.Facilities}`);
    } else {
      if (formErrors.invalidManagerListError) {
        snackbar.enqueueSnackbar("Must have at least one manager");
      }
    }
  }

  function handleChangeAcknowledge(checked: boolean) {
    setIsAcknowledgeWorkWeekChange(checked);
  }

  const closeDownloadTimecardDialog = () => {
    setIsDownloadTimecardDialogOpen(false);
  };

  const openDownloadTimecardDialog = () => {
    setIsDownloadTimecardDialogOpen(true);
  };

  const handleDownloadLoader = (e: boolean) => {
    setIsLoadingTimecardsDownload(e);
  };

  return (
    <div className="view-container">
      <form className="component-form" noValidate autoComplete="off">
        <div className="header">
          <Button
            onClick={onBack}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-button"}>
            <ArrowBackIcon className={"timecard-detail-bottombar-icon"} />
            Back
          </Button>
          <FacilityDetailText facility={facility} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              maxWidth: 350,
            }}>
            <LoadingButton
              onClick={onSave}
              variant="contained"
              size="small"
              loading={isLoading}
              sx={{ width: 100, alignSelf: "flex-end" }}>
              Save
            </LoadingButton>
            {!!facility.lastUpdatedDate && !!facility.lastUpdatedBy && (
              <UpdatedBy name={facility.lastUpdatedBy} date={facility.lastUpdatedDate} />
            )}
          </Box>
        </div>
        <Box mt={6}>
          <Box mb={3}>
            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Typography style={{ fontSize: "1.2rem", color: "#7D7D7D", fontWeight: 600 }}>
                Facility Configuration
              </Typography>
              <LoadingButton
                onClick={openDownloadTimecardDialog}
                variant="text"
                size="small"
                loading={isLoadingTimecardsDownload}>
                Download Timecards
              </LoadingButton>
            </Box>
            <Divider />
          </Box>
          <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
            <WorkWeekStart
              facility={facility}
              formErrors={formError}
              isAcknowledgeWorkWeekChange={isAcknowledgeWorkWeekChange}
              showAcknowledge={showAcknowledge}
              handleChangeAcknowledge={handleChangeAcknowledge}
              handleChange={handleChange}
            />
          </Box>

          <CanRenderChildren permissionName="canUpdateExchangeMsp">
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={facility.isExchange || false}
                    onChange={timecardToggleChange}
                    name="isExchange"
                    color="primary"
                  />
                }
                label="Exchange MSP"
              />
            </div>
          </CanRenderChildren>

          <FormControlLabel
            control={
              <Switch
                checked={facility.additionalDocumentsRequired || false}
                onChange={timecardToggleChange}
                name="additionalDocumentsRequired"
                color="primary"
              />
            }
            label="Additional Documents Required"
          />

          <div>
            {facility.additionalDocumentsRequired && (
              <AdditionalDocuments
                formErrors={formErrors}
                facility={facility}
                handleChange={handleChange}
              />
            )}
          </div>

          <FormControlLabel
            control={
              <Switch
                checked={facility.timecardForwarding || false}
                onChange={timecardToggleChange}
                name="timecardForwarding"
                color="primary"
              />
            }
            label="Timecard Forwarding"
          />
          <div>
            {facility.timecardForwarding && (
              <TimecardForwarding
                formErrors={formErrors}
                facility={facility}
                handleChange={handleChange}
                handleToggle={timecardToggleChange}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={facility.timecardRequiresManagerSignature || false}
                  onChange={timecardRequireManagerSignatureToggleChange}
                  name="timecardRequiresManagerSignature"
                  color="primary"
                />
              }
              label="Manager Signature Required"
              style={{ marginTop: 25 }}
            />
          </div>
        </Box>

        {canSeeManagersList && facility.timecardRequiresManagerSignature && (
          <ManagerList
            entity="facility"
            entityId={facility.id}
            createManager={createManager}
            updateManager={updateManager}
            getManagers={getManagersByFacilityId}
            setValidManagerList={setValidManagerListFromManagerList}
            mustHaveManager={facility.timecardRequiresManagerSignature && facility.isExternal}
          />
        )}
      </form>
      <TimecardsDownloadDialog
        open={isDownloadTimecardDialogOpen}
        onClose={closeDownloadTimecardDialog}
        facilityId={facilityId}
        toggleLoader={handleDownloadLoader}
      />
    </div>
  );
};

export default FacilityDetail;

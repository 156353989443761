import React, { ReactNode } from "react";

type HilightProps = {
  highlight: boolean;
  children: ReactNode;
};

const Highlight: React.FC<HilightProps> = ({ highlight, children }) => {
  if (highlight) {
    return <span style={{ background: "#ffeb3b" }}>{children}</span>;
  }
  return <>{children}</>;
};

export default Highlight;

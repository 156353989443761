import React, { useEffect, useState } from "react";
import BPPTable, { Column } from "components/shared/components/bppTable/BPPTable";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { VmsSetupTable } from "types/vms/vmsSetupTable";
import { getBillingSpecialists, getVmsSetupTable, saveVmsSetupTable } from "api/VmsApi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { parseErrorResponse } from "commons/utils";
import ListSelector from "shared/listSelector/ListSelector";
import { ItemList } from "types/itemList";

type VmsSetupTableIds = "id" | "name" | "displayName" | "billingSpecialists" | "lastModified";

const VmsSetup = () => {
  const [vmses, setVmses] = useState<VmsSetupTable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedVms, setSelectedVms] = useState<VmsSetupTable>();
  const [isVmsModalOpen, setIsVmsModalOpen] = useState(false);
  const [billingSpecialists, setBillingSpecialists] = useState<ItemList[]>([]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  function getColumns(): Column<VmsSetupTableIds, VmsSetupTable>[] {
    return [
      {
        id: "name",
        label: "Name",
        format: (value, data) => (
          <Button variant="text" onClick={() => openModal(data)}>
            {value}
          </Button>
        ),
      },
      {
        id: "displayName",
        label: "Display Name",
      },
      {
        id: "billingSpecialists",
        label: "Billing Specialists",
        format: (value, data) => data.billingSpecialists.map((x) => x.name).join(", "),
      },
      {
        id: "lastModified",
        label: "Last Updated",
        format: (value, data) =>
          data.dateLastModifiedUtc
            ? `${moment(new Date(data.dateLastModifiedUtc)).format("MM/DD/YYYY HH:mm")} by ${
                data.lastModifiedBy
              }`
            : "",
      },
    ];
  }

  const getVmses = async () => {
    try {
      const vmsesApi = await getVmsSetupTable();
      setVmses(vmsesApi);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error loading VMS data", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (vms: VmsSetupTable) => {
    setSelectedVms({ ...vms });
    setIsVmsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedVms(undefined);
    setIsVmsModalOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.name) {
      if (selectedVms) {
        selectedVms.displayName = event.target.value as string;
        setSelectedVms({ ...selectedVms });
      }
    }
  };

  const handleSpecialistsSelected = (recruiters: ItemList[]) => {
    if (!selectedVms) {
      return;
    }

    selectedVms.billingSpecialists = recruiters;
    setSelectedVms({ ...selectedVms });
  };

  const onSubmit = async () => {
    if (!selectedVms) return;

    if (!selectedVms.displayName) {
      enqueueSnackbar("Display name is required", { variant: "error" });
      return;
    }

    if (selectedVms.displayName.length > 100) {
      enqueueSnackbar("Display name cannot be longer than 100 characters", { variant: "error" });
      return;
    }

    if (selectedVms) {
      try {
        await saveVmsSetupTable(selectedVms);
        enqueueSnackbar("Changes saved successfully", { variant: "success" });
        setIsVmsModalOpen(false);
        await getVmses();
      } catch (error: any) {
        const errorMessage = await parseErrorResponse(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "VMS Setup" });
    getVmses().catch((error) => console.log(error));
    getBillingSpecialists()
      .then((data) => setBillingSpecialists(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <BPPTable<VmsSetupTable, VmsSetupTableIds>
        data={vmses}
        columns={getColumns()}
        loading={isLoading}
        maxHeight="calc(100vh - 240px)"
      />

      {selectedVms && (
        <Dialog open={isVmsModalOpen} onClose={closeModal} maxWidth="md" fullWidth>
          <DialogTitle>{selectedVms.name}</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                label="Display name"
                variant="outlined"
                name="displayName"
                value={selectedVms.displayName}
                fullWidth
                onChange={handleChange}
              />
            </Box>

            <ListSelector
              items={billingSpecialists}
              handleSelectedItems={handleSpecialistsSelected}
              selectedItems={selectedVms.billingSpecialists}
              itemsName="Billing Specialists"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
            <Button color="primary" onClick={onSubmit}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default VmsSetup;

import React, { useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as Enums from "../../commons/Enums";
import AdpCodeInput from "./AdpCodeInput";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import ConfirmationPageFile from "./ConfirmationPageFile";
import FacilityReportAttachedIcon from "../shared/components/FacilityReportAttachedIcon";
import FileFordwardedIcon from "../shared/components/FileFordwardedIcon";
import HCPAssignmentHeader, {
  ColumnWidths,
} from "components/shared/components/HCPAssignmentHeader";
import HightlightIcon from "components/shared/components/HighlightIcon";
import PayrollApi from "api/PayrollApi";
import SignatureIcon from "components/shared/components/SignatureIcon";
import TimeCardAdjusmentsIcon from "../shared/components/TimeCardAdjusmentsIcon";
import TimecardDetailTopbarCallOffs from "./TimecardDetailTopbarCallOffs";
import TimecardFileIcon from "../shared/components/TimecardFileIcon";
import TimecardIsLateIcon from "../shared/components/TimecardIsLateIcon";
import UserNotifications from "./UserNotifications";
import { HeaderNotesList } from "../notes/HeaderNotesList";
import { TimesheetStatus } from "../../commons/Enums";
import { useHighlightIcon } from "hooks";
import { usePermissions } from "hooks";

type TimecardDetailTopbarProps = {
  jobAssignment: any;
  onConfirmationPageFileUploaded: () => void;
  displayIcons: boolean;
  isReadOnly: boolean;
  fetchAssignments: () => void;
  onMessagesRead: () => void;
  onAdpCodeUpdated: (adpCode: string | null) => void;
};

const shouldOpenPDF = (status: string) => {
  switch (status) {
    case TimesheetStatus.DRAFT:
    case TimesheetStatus.REJECTED:
    case TimesheetStatus.RETURNED:
      return true;
    default:
      return false;
  }
};

const TimecardDetailTopbar = ({
  jobAssignment,
  onConfirmationPageFileUploaded,
  displayIcons,
  isReadOnly,
  fetchAssignments,
  onMessagesRead,
  onAdpCodeUpdated,
}: TimecardDetailTopbarProps) => {
  const [jobAssignmentNotesCount, setJobAssignmentNotesCount] = useState(
    jobAssignment?.jobAssignmentNotes.length ?? 0
  );

  const { canOpenTimecardAutomatically } = usePermissions();

  const { iconColor, showTooltip, tooltipText } = useHighlightIcon(jobAssignment);

  const showEditAdpCode =
    jobAssignment.status !== TimesheetStatus.APPROVED &&
    jobAssignment.status !== TimesheetStatus.BILLING;

  useEffect(() => {
    if (
      shouldOpenPDF(jobAssignment.status) &&
      canOpenTimecardAutomatically &&
      jobAssignment.timecardUploadId
    ) {
      PayrollApi.openTimecardFile(jobAssignment.timecardUploadId);
    }
  }, []);

  const timecardButtons = (
    <>
      {displayIcons && (
        <div className="timecard-tile-fileicons">
          <SignatureIcon
            signatureStatus={jobAssignment.managerSignatureStatus}
            timesheetId={jobAssignment.id}
            hassEditRequest={jobAssignment.hasEditRequest}
            refreshData={fetchAssignments}
            facilityId={jobAssignment.clientId}
          />
          <TimecardFileIcon
            timecardUploadId={jobAssignment.timecardUploadId}
            uploadSource={jobAssignment.timecardUploadSource}
          />
          <FacilityReportAttachedIcon
            timesheetId={jobAssignment.id}
            uploadSource={jobAssignment.facilityReportUploadSource}
          />
          <TimecardIsLateIcon isLate={jobAssignment.isLate} />
          <HightlightIcon
            showTooltip={showTooltip}
            iconColor={iconColor}
            tooltipText={tooltipText}></HightlightIcon>
          <FileFordwardedIcon
            forwardingStatus={jobAssignment.forwardingStatus}
            timesheetId={jobAssignment.id}
            icon="forward_to_inbox"
          />
          <TimeCardAdjusmentsIcon
            isAdjustment={jobAssignment.isAdjustment}
            hasAdjustment={jobAssignment.hasAdjustment}
            dtHoursAdjusted={jobAssignment.dtHoursAdjusted}
          />
        </div>
      )}
    </>
  );

  const colWidths: ColumnWidths[] = [
    { minWidth: 1536, columnSpans: [3, 7, 2] },
    { minWidth: 1200, columnSpans: [4, 6, 2] },
    { minWidth: 900, columnSpans: [5, 5, 2] },
    { minWidth: 600, columnSpans: [6, 4, 2] },
    { minWidth: 0, columnSpans: [5, 5, 2] },
  ];

  return (
    <div id="timecard-topbar" className="timecard-detail-topbar">
      <div className="timecard-detail-topbar-details">
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}>
          <p className="timecard-detail-topbar-details-fullname">
            {jobAssignment.candidateFullName}
          </p>
          <UserNotifications
            candidateFullName={jobAssignment.candidateFullName}
            hasUnreadMessages={jobAssignment.hasUnreadMessages}
            onMessagesRead={onMessagesRead}
            timesheetId={jobAssignment.id}
          />
        </Box>

        <HCPAssignmentHeader
          jobAssignment={jobAssignment}
          thirdColumnNodes={timecardButtons}
          columnWidths={colWidths}
        />

        <Box display={"flex"} gap={1} alignItems={"flex-end"}>
          <CanRenderChildren permissionName="canManageAdpCode" featureFlagName="isAdpCodeOn">
            <AdpCodeInput
              jobAssignment={jobAssignment}
              onAdpCodeUpdated={onAdpCodeUpdated}
              readonly={!showEditAdpCode}
            />
          </CanRenderChildren>
        </Box>

        <TimecardDetailTopbarCallOffs
          showInfo={true}
          hospitalCallOffs={jobAssignment.jobAssignmentCallOffHospital}
          otherCallOffs={jobAssignment.jobAssignmentCallOffOther}
          sickCallOffs={jobAssignment.jobAssignmentCallOffSick}
          personalCallOffs={
            jobAssignment.jobAssignmentCallOffPersonal
          }></TimecardDetailTopbarCallOffs>

        <Accordion style={{ overflow: "auto", marginTop: "10px", marginBottom: "10px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <Typography>Additional Job Assignment Details</Typography>
              <Box className="timecard-tab-title-badge">
                <Typography fontSize={10}>{jobAssignmentNotesCount}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block", paddingBottom: 0, paddingTop: 0 }}>
            <div>
              <HeaderNotesList
                jobAssignmentId={jobAssignment.jobId}
                assignmentsFetched={(count: number) => setJobAssignmentNotesCount(count)}
                isReadOnly={isReadOnly}
              />
              <CanRenderChildren permissionName="canSeeConfirmationPageFile">
                <ConfirmationPageFile
                  jobId={jobAssignment.id}
                  confirmationPageUploadId={jobAssignment.confirmationPageUploadId}
                  onFileUploaded={onConfirmationPageFileUploaded}
                  uploading={
                    jobAssignment.confirmationPageUploading &
                    Enums.JobAssignmentFileTypes.CONFIRMATION_PAGE
                  }
                  isReadOnly={isReadOnly}
                />
              </CanRenderChildren>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default TimecardDetailTopbar;

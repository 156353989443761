import React, { ReactNode } from "react";

type TitleProps = {
  children: ReactNode;
};

const Title: React.FC<TitleProps> = ({ children }) => {
  return <span className="darkHeaderText">{children}</span>;
};

export default Title;

import React from "react";

import { Box } from "@mui/system";
import { List, Typography } from "@mui/material";

import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import ConfirmationPageFileIcon from "./ConfirmationPageFileIcon";
import HCPAssignmentHeader, {
  ColumnWidths,
} from "components/shared/components/HCPAssignmentHeader";
import TimecardDetailTopbarCallOffs from "components/timecards/TimecardDetailTopbarCallOffs";
import UserNotifications from "components/timecards/UserNotifications";
import { JobAssignmentNote } from "components/notes/JobAssignmentNote";
import { JobAssignmentSearchHistory } from "types/history/JobAssignmentCardHistory";
import { Note } from "types/notes";
import { WorkWeek } from "commons/Enums";

type JobAssignmentHeaderProps = {
  jobAssignment: JobAssignmentSearchHistory;
  hasUnreadMessages: boolean;
  onMessagesRead: () => void;
};

const JobAssignmentHeader = (props: JobAssignmentHeaderProps) => {
  const { jobAssignment, hasUnreadMessages, onMessagesRead } = props;

  const lastTimesheetId = jobAssignment.jobAssignmentTimecardsHistory[0].timesheetId;

  const jobAssignmentNotesSection = (
    <Box display="flex" flexDirection="column" ml={6}>
      <Typography fontSize={14} color="#999">
        <span className="darkHeaderText allCaps">Job Assignment Notes</span>
      </Typography>
      {jobAssignment?.jobAssignmentNotes.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            overflowY: "auto",
            maxHeight: "80px",
            flexDirection: "column",
            padding: "0 5px 0 5px",
            marginBottom: "0",
          }}>
          <List style={{ width: "100%", resize: "none" }}>
            {jobAssignment?.jobAssignmentNotes.map((jobAssignmentNote: Note) => (
              <JobAssignmentNote key={jobAssignmentNote.noteId} note={jobAssignmentNote} />
            ))}
          </List>
        </Box>
      ) : (
        <Typography>No notes have been added</Typography>
      )}
    </Box>
  );

  const colWidths: ColumnWidths[] = [
    { minWidth: 2000, columnSpans: [3, 4, 5] },
    { minWidth: 1536, columnSpans: [3, 4, 5] },
    { minWidth: 1200, columnSpans: [4, 4, 4] },
    { minWidth: 900, columnSpans: [5, 4, 3] },
    { minWidth: 600, columnSpans: [6, 3, 3] },
    { minWidth: 0, columnSpans: [6, 3, 3] },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }} mx={5} mt={3} mb={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}>
        <Box pl={1}>
          <Typography fontSize={20} fontWeight="bold">
            {jobAssignment.candidateFullName}

            <UserNotifications
              candidateFullName={jobAssignment.candidateFullName}
              hasUnreadMessages={hasUnreadMessages}
              onMessagesRead={onMessagesRead}
              timesheetId={lastTimesheetId}
              isReadOnly={true}
            />
          </Typography>

          <HCPAssignmentHeader
            jobAssignment={props.jobAssignment}
            thirdColumnNodes={jobAssignmentNotesSection}
            columnWidths={colWidths}
          />

          <TimecardDetailTopbarCallOffs
            hospitalCallOffs={jobAssignment.jobAssignmentCallOffHospital}
            otherCallOffs={jobAssignment.jobAssignmentCallOffOther}
            sickCallOffs={jobAssignment.jobAssignmentCallOffSick}
            personalCallOffs={
              jobAssignment.jobAssignmentCallOffPersonal
            }></TimecardDetailTopbarCallOffs>

          {jobAssignment.workWeek !== null && jobAssignment.workWeek !== 0 && (
            <Typography fontSize={12} color="#999" textTransform="uppercase">
              <span style={{ background: "#ffeb3b" }}>
                Alternate work week starting: {WorkWeek[jobAssignment.workWeek as never]}
              </span>
            </Typography>
          )}

          <CanRenderChildren permissionName="canSeeConfirmationPageFile">
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography fontSize={14}>Confirmation Page:</Typography>
              <ConfirmationPageFileIcon
                confirmationPageUploadId={jobAssignment.confirmationPageUploadId}
              />
            </Box>
          </CanRenderChildren>
        </Box>
      </Box>
    </Box>
  );
};

export default JobAssignmentHeader;

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { Link, useHistory } from "react-router-dom";
import { ManagerList } from "components/shared/components/managers/ManagerList";
import {
  createContract,
  getAvailableContracts,
  getContractById,
  updateContract,
} from "api/ContractsApi";
import { Contract } from "types/facility/contract";
import { useSnackbar } from "notistack";
import { createManager, getManagersByFacilityId, updateManager } from "api/ManagersApi";
import { LoadingButton } from "@mui/lab";
import { SetupTab } from "types/setup/SetupTab";

type ContractDetailsProps = {
  match?: any;
};

export const ContractDetails = (props: ContractDetailsProps) => {
  const { contractId } = props.match.params;

  const dispatch = useDispatch();
  const history = useHistory();
  const snackbar = useSnackbar();

  const [contract, setContract] = useState<Contract>({
    id: contractId,
    name: "",
    requireManagerSignature: false,
    includeInTriagePlusReport: false,
  });

  const [availableContracts, setAvailableContracts] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleGetAvailableContracts = async () => {
    const response = await getAvailableContracts();
    setAvailableContracts(response);
  };

  const handleGetFacilityInfo = async (contractId: string) => {
    try {
      const resp = await getContractById(contractId);
      setContract(resp);
    } catch (e) {
      snackbar.enqueueSnackbar(`Error loading: ${e}`);
    }
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Contract Details" });
  }, []);

  useEffect(() => {
    if (!contractId) {
      handleGetAvailableContracts();
    } else {
      handleGetFacilityInfo(contractId);
    }
  }, [contractId]);

  const timecardToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setContract({ ...contract, [event.target.name]: event.target.checked });
  };

  const selectContract = (value: string) => {
    const selectedContract = availableContracts.find((x) => x.name === value);
    setContract(selectedContract!);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      await updateContract(contractId, contract);
      snackbar.enqueueSnackbar("Changes saved");
    } catch (e) {
      snackbar.enqueueSnackbar(`Error updating: ${e}`);
    } finally {
      setLoading(false);
      setDirty(false);
    }
  };

  const onCreate = async () => {
    try {
      setLoading(true);
      const newContract = await createContract(contract);
      history.replace(`/contracts/contractDetails/${newContract.id}`);
      snackbar.enqueueSnackbar("Contract created");
    } catch (e) {
      snackbar.enqueueSnackbar(`Error creating: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="view-container">
      <form className="component-form" noValidate autoComplete="off">
        <Box mt={2}>
          <Button
            to={`/setup?tab=${SetupTab.Contracts}`}
            component={Link}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-button"}>
            <ArrowBackIcon className={"timecard-detail-bottombar-icon"} />
            Back
          </Button>
          {contractId && (
            <LoadingButton
              onClick={onSave}
              variant="contained"
              size="small"
              loading={loading}
              disabled={!dirty}
              className={"timecard-detail-bottombar-button"}>
              <SendIcon className={"timecard-detail-bottombar-icon"} />
              Save
            </LoadingButton>
          )}
          {!contractId && (
            <LoadingButton
              onClick={onCreate}
              variant="contained"
              size="small"
              loading={loading}
              disabled={
                !(
                  (contract.requireManagerSignature || contract.includeInTriagePlusReport) &&
                  contract.name
                )
              }
              className={"timecard-detail-bottombar-button"}>
              <SendIcon className={"timecard-detail-bottombar-icon"} />
              Create
            </LoadingButton>
          )}
        </Box>
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          {!contractId ? (
            <FormControl sx={{ minWidth: 350 }} margin="normal">
              <Autocomplete
                options={availableContracts.map((x) => ({ label: x.name }))}
                sx={{ width: 700 }}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                onChange={(_, value) => {
                  selectContract(value?.label ?? "");
                }}
                renderInput={(params) => <TextField {...params} label="Contract Name" />}
              />
            </FormControl>
          ) : (
            <TextField
              id="contract-name"
              label="Contract Name"
              variant="outlined"
              disabled
              value={contract.name}
              fullWidth
            />
          )}
        </Box>
        <Box mt={2}>
          {!contractId && (
            <Typography fontSize={13} color="#484848" fontWeight={500}>
              Note: Adding a Contract requires at least one of the following options to be enabled
            </Typography>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={contract.requireManagerSignature || false}
                onChange={timecardToggleChange}
                name="requireManagerSignature"
                color="primary"
              />
            }
            label="Manager Signature Required"
          />
          <FormControlLabel
            control={
              <Switch
                checked={contract.includeInTriagePlusReport || false}
                onChange={timecardToggleChange}
                name="includeInTriagePlusReport"
                color="primary"
              />
            }
            label="Include In Triage Plus Report"
          />
        </Box>
        {contract.requireManagerSignature && contractId && (
          <ManagerList
            entity="contract"
            entityId={contract.id}
            createManager={createManager}
            getManagers={getManagersByFacilityId}
            updateManager={updateManager}
            mustHaveManager={false}
            setValidManagerList={() => {}}
          />
        )}
      </form>
    </div>
  );
};

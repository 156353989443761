import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { Contracts } from "components/contracts/Contracts";
import Facilities from "components/facilities/Facilities";
import UnlockPayPeriod from "components/unlock/UnlockPayPeriod";
import { usePermissions } from "hooks";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { SetupTab } from "types/setup/SetupTab";
import VmsSetup from "./VmsSetup";

const Setup = () => {
  const [selectedTab, setSelectedTab] = useState(SetupTab.Facilities);
  const { canSeeContracts, canManageFacilities, canSeeVmsSetup, canUnlockPayPeriod } =
    usePermissions();

  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const selectedTabFromQueryParsed = Object.values(SetupTab).find((v) => v === newValue);

    if (selectedTabFromQueryParsed) {
      setSelectedTab(selectedTabFromQueryParsed);
    }
  };

  const selectTabFromQuery = () => {
    const query = window.location.search.substring(1);
    const queryStringVars = query.split("&");
    const selectedTabFromQuery = queryStringVars.find((v) => v.includes("tab="))?.split("=")[1];
    const selectedTabFromQueryParsed = Object.values(SetupTab).find(
      (v) => v === selectedTabFromQuery
    );

    if (selectedTabFromQueryParsed) {
      setSelectedTab(selectedTabFromQueryParsed);
    }
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Setup" });

    selectTabFromQuery();
  }, []);
  return (
    <div className="view-container">
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} centered onChange={handleChange}>
          {canManageFacilities && <Tab label="Facilities" value={SetupTab.Facilities} />}
          {canSeeContracts && <Tab label="Contracts" value={SetupTab.Contracts} />}
          {canSeeVmsSetup && <Tab label="VMS" value={SetupTab.Vms} />}
          {canUnlockPayPeriod && <Tab label="Pay Period" value={SetupTab.PayPeriod} />}
        </Tabs>

        {canManageFacilities && (
          <TabPanel value={SetupTab.Facilities}>
            <Facilities />
          </TabPanel>
        )}

        {canSeeContracts && (
          <TabPanel value={SetupTab.Contracts}>
            <Contracts />
          </TabPanel>
        )}

        {canSeeVmsSetup && (
          <TabPanel value={SetupTab.Vms}>
            <VmsSetup />
          </TabPanel>
        )}

        {canUnlockPayPeriod && (
          <TabPanel value={SetupTab.PayPeriod}>
            <UnlockPayPeriod />
          </TabPanel>
        )}
      </TabContext>
    </div>
  );
};

export default Setup;

import React, { ReactNode } from "react";

import { Grid, Typography } from "@mui/material";

type CellProps = {
  children: ReactNode;
};

const Cell: React.FC<CellProps> = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Typography fontSize={14} color="#999">
        {children}
      </Typography>
    </Grid>
  );
};

export default Cell;

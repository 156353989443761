import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import JobAssignmentApi from "api/JobAssignmentApi";
import { useFeatureFlags } from "commons/Features";
import { downloadBlob, parseErrorResponse } from "commons/utils";
import PayPeriodRangeSelector from "components/history/PayPeriodRangeSelector";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type NoteAuditReportDialogProps = {
  open: boolean;
  onClose: () => void;
};

const NoteAuditReportDialog: React.FC<NoteAuditReportDialogProps> = ({ open, onClose }) => {
  const [maxDateFilters, setMaxDateFilters] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setisLoading] = useState(false);

  const headerStartDate = useSelector((state: RootState) => state.header.startDate);
  const headerEndDate = useSelector((state: RootState) => state.header.endDate);
  const { firstTimesheetPeriodStartDate } = useFeatureFlags();
  const { enqueueSnackbar } = useSnackbar();

  const sidebarOptions = [
    {
      label: "All Periods",
      range: () => ({
        startDate: new Date(firstTimesheetPeriodStartDate ?? new Date()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
    {
      label: "Previous 4 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(4, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
    {
      label: "Previous 8 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(8, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
    {
      label: "Previous 12 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(12, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
  ];

  const handleOnFilterDateChange = (startDate?: string, endDate?: string) => {
    setStartDate(startDate ?? new Date().toISOString().split("T")[0]);
    setEndDate(endDate ?? new Date().toISOString().split("T")[0]);
  };

  const onGenerateReport = async () => {
    setAnchorEl(null);
    setisLoading(true);
    try {
      const blob = await JobAssignmentApi.getNoteAuditReport(startDate, endDate);
      await downloadBlob(new Blob([blob]), `report.csv`);
    } catch (error: any) {
      const erorrMessage = await parseErrorResponse(error);
      enqueueSnackbar(erorrMessage, { variant: "error" });
    } finally {
      onClose();
      setisLoading(false);
    }
  };

  useEffect(() => {
    setMaxDateFilters(moment(new Date(headerStartDate)).subtract(1, "day").toDate());
    handleOnFilterDateChange(
      new Date(moment(headerStartDate).subtract(1, "weeks").toDate()).toISOString(),
      new Date(moment(headerEndDate).subtract(1, "weeks").toDate()).toISOString()
    );
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{"Note Audit Report"}</DialogTitle>
      <DialogContent>
        {maxDateFilters && (
          <PayPeriodRangeSelector
            title="Period Range"
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            onHandleChange={handleOnFilterDateChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDateFilters}
            buttonPickerVariant="text"
            customSidebarOptions={sidebarOptions}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={onGenerateReport} color="primary" variant="contained" disabled={isLoading}>
          {isLoading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NoteAuditReportDialog;
